import React from 'react';

const NotFound = () => {
  return (
    <div style={{height:"70vh"}} className='bg-white d-flex flex-column justify-content-center align-items-center'>
        <h1>404</h1>
        <h1>Not Found</h1>
    </div>
  );
};

export default NotFound;
